import { Ref } from 'vue';
import request from '@/tools/request';

// 列表
export const getList = (params?: any, loading?: Ref<boolean>) => {
  return request({
    url: '/deliverytracking-courier-list.html',
    loading,
    params,
  }) as Promise<any>;
};
