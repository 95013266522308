
import { defineComponent, onMounted, reactive, ref } from 'vue';
import PageHeader from '@/components/pageHeader/PageHeader.vue';
import Table from '@/components/table/Table.vue';
import { getList } from '../api/carrier';

export default defineComponent({
  name: 'Carrier',
  setup() {
    const keyword = ref('');
    const loading = ref(false);
    const pagination = reactive({
      total: 0,
      pageSize: 10,
      currentPage: 1,
    });
    const tableList = ref([]);

    const pageChange = (cur: number) => {
      pagination.currentPage = cur;
      initData();
    };

    const initData = async () => {
      const { data } = await getList(
        { page: pagination.currentPage, page_size: pagination.pageSize, keyword: keyword.value },
        loading,
      );
      tableList.value = data.list;
      pagination.total = Number(data.page_list.total);
    };

    const search = () => {
      pagination.currentPage = 1;
      initData();
    };

    onMounted(initData);

    return {
      keyword,
      loading,
      pagination,
      tableList,
      pageChange,
      search,
    };
  },
  components: { PageHeader, Table },
});
